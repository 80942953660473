import { combineReducers } from "redux";
import { userReducer } from "./userReducer.js";
import { sidebarReducer as sidebarShow } from "./sidebarReducer";
import { dataReducer } from "./dataReducer.js";
import { appReducer } from "./appReducer.js";

const rootReducer = combineReducers({
  currentUser: userReducer,
  data: dataReducer,
  app: appReducer,
  sidebarShow,
});

export default rootReducer;
