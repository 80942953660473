import {
  GET_ALASAN_DISKON_BY_ID,
  GET_ALL_ALASAN_DISKON,
} from "../types/alasanDiskonTypes";
import {
  GET_ALL_BARANG_JASA,
  GET_BARANG_JASA_BY_ID,
} from "../types/barangJasaTypes";
import { GET_ALL_CABANG, GET_CABANG_BY_ID } from "../types/cabangTypes";
import {
  GET_ALL_KELENGKAPAN,
  GET_KELENGKAPAN_BY_ID,
} from "../types/kelengkapanTypes";
import { GET_ALL_KONDISI, GET_KONDISI_BY_ID } from "../types/kondisiTypes";
import { GET_ALL_MEREK, GET_MEREK_BY_ID } from "../types/merekTypes";
import { GET_ALL_PAJAK, GET_PAJAK_BY_ID } from "../types/pajakTypes";
import { GET_ALL_PROBLEM, GET_PROBLEM_BY_ID } from "../types/problemActions";
import { GET_ALL_RMA, GET_RMA_BY_ID } from "../types/rmaTypes";
import { GET_ALL_TIPE, GET_TIPE_BY_ID } from "../types/tipeTypes";

const initialState = {
  dataCabang: {},
  dataCabangById: {
    data: {},
    modal: {},
    input: {},
  },
  dataBarangJasa: {},
  dataBarangJasaById: {
    data: {},
    modal: {},
    input: {},
  },
  dataKelengkapan: {},
  dataKelengkapanById: {
    data: {},
    modal: {},
    input: {},
    inputBj: [],
  },
  dataKondisi: {},
  dataKondisiById: {
    data: {},
    modal: {},
    input: {},
    inputBj: [],
  },
  dataMerek: {},
  dataMerekById: {
    data: {},
    modal: {},
    input: {},
  },
  dataProblem: {},
  dataProblemById: {
    data: {},
    modal: {},
    input: {},
    inputBj: [],
  },
  dataRma: {},
  dataRmaById: {
    data: {},
    modal: {},
    input: {},
  },
  dataTipe: {},
  dataTipeById: {
    data: {},
    modal: {},
    input: {},
    inputBj: "",
    inputMerek: "",
  },
  dataPajak: {},
  dataPajakById: {
    data: {},
    modal: {},
    input: {},
  },
  dataAlasanDiskon: {},
  dataAlasanDiskonById: {
    data: {},
    modal: {},
    input: {},
  },
};

export const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_CABANG:
      return {
        ...initialState,
        dataCabang: action.payload,
      };
    case GET_CABANG_BY_ID:
      return {
        ...initialState,
        dataCabangById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
        },
      };
    case GET_ALL_BARANG_JASA:
      return {
        ...initialState,
        dataBarangJasa: action.payload,
      };
    case GET_BARANG_JASA_BY_ID:
      return {
        ...initialState,
        dataBarangJasaById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
        },
      };
    case GET_ALL_KELENGKAPAN:
      return {
        ...initialState,
        dataKelengkapan: action.payload,
      };
    case GET_KELENGKAPAN_BY_ID:
      return {
        ...initialState,
        dataKelengkapanById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
          inputBj: action.payload.inputBj,
        },
      };
    case GET_ALL_KONDISI:
      return {
        ...initialState,
        dataKondisi: action.payload,
      };
    case GET_KONDISI_BY_ID:
      return {
        ...initialState,
        dataKondisiById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
          inputBj: action.payload.inputBj,
        },
      };
    case GET_ALL_MEREK:
      return {
        ...initialState,
        dataMerek: action.payload,
      };
    case GET_MEREK_BY_ID:
      return {
        ...initialState,
        dataMerekById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
        },
      };
    case GET_ALL_PROBLEM:
      return {
        ...initialState,
        dataProblem: action.payload,
      };
    case GET_PROBLEM_BY_ID:
      return {
        ...initialState,
        dataProblemById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
          inputBj: action.payload.inputBj,
        },
      };
    case GET_ALL_RMA:
      return {
        ...initialState,
        dataRma: action.payload,
      };
    case GET_RMA_BY_ID:
      return {
        ...initialState,
        dataRmaById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
        },
      };
    case GET_ALL_TIPE:
      return {
        ...initialState,
        dataTipe: action.payload,
      };
    case GET_TIPE_BY_ID:
      return {
        ...initialState,
        dataTipeById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
          inputBj: action.payload.inputBj,
          inputMerek: action.payload.inputMerek,
        },
      };
    case GET_ALL_PAJAK:
      return {
        ...initialState,
        dataPajak: action.payload,
      };
    case GET_PAJAK_BY_ID:
      return {
        ...initialState,
        dataPajakById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
        },
      };
    case GET_ALL_ALASAN_DISKON:
      return {
        ...initialState,
        dataAlasanDiskon: action.payload,
      };
    case GET_ALASAN_DISKON_BY_ID:
      return {
        ...initialState,
        dataAlasanDiskonById: {
          data: action.payload.data,
          modal: action.payload.modal,
          input: action.payload.input,
        },
      };
    default:
      return state;
  }
};
