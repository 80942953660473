import { MODAL_LOADING, SHOW_ALERT, TABLE_LOADING } from "../types/appTypes";

const initialState = {
  tableLoading: false,
  modalLoading: false,
  alert: {
    show: false,
    type: "success",
    content: {},
  },
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case TABLE_LOADING:
      return {
        ...initialState,
        tableLoading: action.payload,
      };
    case MODAL_LOADING:
      return {
        ...initialState,
        modalLoading: action.payload,
      };
    case SHOW_ALERT:
      return {
        ...initialState,
        alert: {
          show: action.payload.show,
          type: action.payload.type,
          content: action.payload.content,
        },
      };
    default:
      return state;
  }
};
